"use strict";
/* 
 * core.js should be the same for both store.admin and store.web. 
 * When changing one of the core.js files, make sure that you
 * apply the same exact changes to the other core.js file.
 * Use WinMerge or a similar application to compare the files.
 */

//Class
var Class = (function () {
    var _register = function (namespace) {
        var parts = namespace.split('.'),
            current = window,
            part = undefined;

        for (var i = 0; i < parts.length; i++) {
            part = parts[i];
            current[part] = current[part] || {};
            current = current[part];
        }

        return current;
    };

    var _extend = function (sub, base) {
        sub.prototype = Object.create(base.prototype);
        sub.prototype.constructor = sub;
    };

    return {
        register: _register,
        extend: _extend
    };
})();

//View Manager
Class.register('App.ViewManager');
App.ViewManager = (function () {
    var _views = new Array();

    var _addView = function (view) {
        _views.push(view);
    };

    var _removeView = function (view) {
        var index = _views.indexOf(view);
        if (index >= 0) {
            _views.splice(index, 1);
        }
    };

    var _loadViews = function () {
        for (var i = 0; i < _views.length; i++) {
            var view = _views[i];
            view.load();
        }
    };

    var _destroy = function () {
        for (var i = 0; i < _views.length; i++) {
            var view = _views[i];
            view.destroy();
        }
    };

    return {
        addView: _addView,
        removeView: _removeView,
        loadViews: _loadViews,
        destroy: _destroy
    };
})();

//View
Class.register('App.View');
App.View = (function ($) {
    /* Public */
    var self = function (selector) {
        this._selector = selector;
    };

    self.prototype.load = function () {

    };

    self.prototype.destroy = function () {

    };

    self.prototype.select = function (selector) {
        if (selector) {
            return $(selector, this._selector);
        } else {
            return $(this._selector);
        }
    };

    self.prototype.compileTemplate = function (selector) {
        var source = $(selector).html();
        var template = Handlebars.compile(source);
        return template;
    };

    self.prototype.renderTemplate = function (selector, template, model) {
        var element = this.select(selector);
        element.empty();
        element.html(template(model));
    };

    return self;
})(jQuery);

//Event Manager
Class.register('App.EventManager');
App.EventManager = (function () {
    var _eventHandlers = new Object();

    var _addEventHandler = function (event, handler) {
        //create array of handlers for the event name if it doesn't already exist
        if (!(event in _eventHandlers)) {
            _eventHandlers[event] = new Array();
        }
        //get list of handlers and add handler if it is has not been already added
        var handlers = _eventHandlers[event];
        if (handlers.indexOf(handler) < 0) {
            _eventHandlers[event].push(handler);
        }
    };

    var _removeEventHandler = function (event, handler) {
        //check if array of handlers exists for the event name
        if (event in _eventHandlers) {
            //get list of handlers and remove event handler if it has been already added
            var handlers = _eventHandlers[event];
            if (handlers.indexOf(handler) >= 0) {
                _eventHandlers[event].splice(index, 1);
            }
        }
    };

    var _dispatchEvent = function (event, data) {
        //check if array of handlers exists for event name
        if (event in _eventHandlers) {
            //get list of handlers
            var handlers = _eventHandlers[event];
            //call individual handlers
            for (var i = 0; i < handlers.length; i++) {
                handlers[i].call(null, data);
            }
        }
    };

    var _destroy = function () {
        for (var event in _eventHandlers) {
            //clear individual handlers
            var handlers = _eventHandlers[event];
            handlers.splice(0, handlers.length);
            //delete event name key
            delete _eventHandlers[event];
        }
    };

    return {
        addEventHandler: _addEventHandler,
        removeEventHandler: _removeEventHandler,
        dispatchEvent: _dispatchEvent,
        destroy: _destroy
    };
})();

//Api
Class.register('App.Api');
App.Api = (function () {
    var _index = 0;
    var _uiBlocker = null;

    var _call = function (options) {
        if (!options.hideUIBlocker) {
            //increase the index
            _index++;

            //show ui blocker if the first request is pending
            if (_index === 1) {
                //create ui blocker
                _uiBlocker = $('<div class="ui-blocker"><div class="image"></div></div>').appendTo('body');

                //set height to document height
                _uiBlocker.height($(document).height());

                //show ui blocker
                _uiBlocker.show();
            }
        }

        //get ajax object
        var ajax = options.form ? _getAjaxUpload(self, options) : _getAjaxCall(self, options);

        //make ajax call
        ajax.done(function (data, status, xhr) {
            //decrese the index
            _index--;

            //hide ui blocker if no more requests are being processed
            if (_index === 0) {
                if (_uiBlocker) {
                    _uiBlocker.remove();
                }
            }

            //call handler
            if (options.resultHandler) {
                options.resultHandler(data);
            }
        }).fail(function (xhr, textStatus, error) {
            //decrese the index
            _index--;

            //hide ui blocker if no more requests are being processed
            if (_index === 0) {
                if (_uiBlocker) {
                    _uiBlocker.remove();
                }
            }

            //handle canceled requests
            if (xhr.readyState === 0 && xhr.status === 0) {
                //handle timeout errors
                if (textStatus === 'timeout') {
                    App.EventManager.dispatchEvent("TimeoutError");
                } else {
                    return;
                }
            }
            //handle unauthorized error
            else if (xhr.status === 401) {
                App.EventManager.dispatchEvent("UnauthorizedError");
            }
            //handle server error
            else if (textStatus === 'error' || textStatus === 'parsererror') {
                if (options.errorHandler) {
                    options.errorHandler({
                        url: options.url,
                        method: _getMethod(self, options),
                        xhr: xhr,
                        message: error ? error : textStatus
                    });
                } else {
                    App.EventManager.dispatchEvent("ServerError", {
                        url: options.url,
                        method: _getMethod(self, options),
                        xhr: xhr,
                        message: error ? error : textStatus
                    });
                }
            }
            //handle timeout errors
            else  if (textStatus === 'timeout') {
                App.EventManager.dispatchEvent("TimeoutError");
            }
        });
    };

    var _getAjaxCall = function (self, options) {
        return $.ajax({
            type: _getMethod(self, options),
            contentType: options.contentType,
            cache: false,
            timeout: 20000,
            url: options.url,
            data: options.parameters
        });
    };

    var _getAjaxUpload = function (self, options) {
        return $.ajax({
            type: _getMethod(self, options),
            contentType: false,
            cache: false,
            url: options.url,
            data: options.form,
            processData: false
        });
    };

    var _getMethod = function (self, options) {
        return options.method ? options.method : 'POST';
    };

    return {
        call: _call
    };
})(jQuery);

//Cookie
Class.register('App.Cookie');
App.Cookie = (function () {
    var _getValue = function (name) {
        if (!App.TypeUtils.isString(name)) throw TypeError('Name is not a string.');
        if (App.TypeUtils.isEmptyString(name)) throw Error('Name is empty.');

        if (document.cookie && document.cookie.length > 0) {
            var nvArray = document.cookie.split(';');

            if (nvArray && nvArray.length > 0) {
                for (var i = 0; i < nvArray.length; i++) {
                    var nv = nvArray[i].split('=');

                    if (nv.length === 2) {
                        var nvName = nv[0].trim();
                        var nvValue = nv[1].trim();

                        if (decodeURIComponent(nvName).toLowerCase() === name.toLowerCase()) {
                            return decodeURIComponent(nvValue);
                        }
                    }
                }
            }
        }

        return null;
    };

    var _setValue = function (name, value, daysToExpire) {
        if (!App.TypeUtils.isString(name)) throw TypeError('Name is not a string.');
        if (App.TypeUtils.isEmptyString(name)) throw Error('Name is empty.');
        if (!App.TypeUtils.isString(value)) throw TypeError('Value is not a string.');
        if (daysToExpire && !App.TypeUtils.isNumber(daysToExpire)) throw TypeError('DaysToExpire is not a number.');

        if (!daysToExpire) {
            document.cookie = encodeURIComponent(name) + '=' + encodeURIComponent(value.trim()) + ';expires=Fri, 31 Dec 9999 23:59:59 GMT;path=/';
        } else {
            var today = new Date();
            today.setDate(today.getDate() + daysToExpire);
            document.cookie = encodeURIComponent(name) + '=' + encodeURIComponent(value.trim()) + ';expires=' + today.toUTCString() + ';path=/';
        }
    };

    var _remove = function (name) {
        if (!App.TypeUtils.isString(name)) throw TypeError('Name is not a string.');
        if (App.TypeUtils.isEmptyString(name)) throw Error('Name is empty.');

        document.cookie = encodeURIComponent(name) + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
    };

    return {
        setValue: _setValue,
        getValue: _getValue,
        remove: _remove
    };
})();

//Storage
Class.register('App.Storage');
App.Storage = (function () {
    var _get = function (key) {
        if (!App.TypeUtils.isString(key)) throw TypeError('Key is not a string.');
        if (App.TypeUtils.isEmptyString(key)) throw Error('Key is empty.');

        var value = localStorage.getItem(key);

        return value ? value.trim() : null;
    };

    var _set = function (key, value) {
        if (!App.TypeUtils.isString(key)) throw TypeError('Key is not a string.');
        if (App.TypeUtils.isEmptyString(key)) throw Error('Key is empty.');
        if (!App.TypeUtils.isString(value)) throw TypeError('Value is not a string.');

        localStorage.setItem(key, value.trim());
    };

    var _isAvailable = function () {
        try {
            if (!localStorage) {
                return false;
            } else {
                var x = '__va_local_storage__';
                localStorage.setItem(x, x);
                localStorage.removeItem(x);
                return true;
            }
        }
        catch (e) {
            return false;
        }
    };

    return {
        get: _get,
        set: _set,
        isAvailable: _isAvailable
    };
})();

//Url
Class.register('App.Url');
App.Url = (function () {
    var _getParameter = function (name) {
        if (!App.TypeUtils.isString(name)) throw TypeError('Name is not a string.');
        if (App.TypeUtils.isEmptyString(name)) throw Error('Name is empty.');

        var search = location.search;

        if (!search) {
            return null;
        }

        search = search.substring(1);

        var nvArray = search.split('&');
        for (var i = 0; i < nvArray.length; i++) {
            var nv = nvArray[i].split('=');

            if (nv.length === 2) {
                var nvName = nv[0].trim();
                var nvValue = nv[1].trim();

                if (decodeURIComponent(nvName).toLowerCase() === name.toLowerCase()) {
                    return decodeURIComponent(nvValue);
                }
            }
        }

        return null;
    };

    var _getIdParameter = function () {
        var pathname = location.pathname;
        var index = pathname.lastIndexOf('/');
        if (index >= 0 && index + 1 < pathname.length) {
            return decodeURIComponent(location.pathname.substring(index + 1).trim());
        } else {
            return null;
        }
    };

    var _getParameterByIndex = function (index) {
        if (!App.TypeUtils.isNumber(index)) throw TypeError('Index is not a number.');
        if (index < 0) throw Error('Index is smaller than zero.');

        var parts = location.pathname.split('/');
        if (index + 1 < parts.length) {
            return decodeURIComponent(parts[index + 1].trim());
        } else {
            return null;
        }
    };

    return {
        getParameter: _getParameter,
        getIdParameter: _getIdParameter,
        getParameterByIndex: _getParameterByIndex
    };
})();

//Validator
Class.register('App.Validator');
App.Validator = (function () {
    var _isEmpty = function (value) {
        if (!App.TypeUtils.isString(value)) throw TypeError('Value is not a string.');

        return App.TypeUtils.isEmptyString(value);
    };

    var _isInt = function (value) {
        if (!App.TypeUtils.isString(value)) throw TypeError('Value is not a string.');
        var regex = /^(0|-?[1-9][0-9]{0,8})$/;
        return regex.test(value);
    };

    var _isDecimal = function (value) {
        if (!App.TypeUtils.isString(value)) throw TypeError('Value is not a string.');

        var regex = /^(0|0(\.[0-9])|-?[1-9][0-9]{0,8}(\.[0-9])?)$/;
        return regex.test(value);
    };

    var _isPhone = function (value) {
        if (!App.TypeUtils.isString(value)) throw TypeError('Value is not a string.');

        var regex = /^[+]{0,2}[0-9]{8,}$/;
        return regex.test(value);
    };

    var _isEmail = function (value) {
        if (!App.TypeUtils.isString(value)) throw TypeError('Value is not a string.');

        var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(value);
    };

    return {
        isEmpty: _isEmpty,
        isInt: _isInt,
        isDecimal: _isDecimal,
        isPhone: _isPhone,
        isEmail: _isEmail
    };
})();

Class.register('App.TypeUtils');
App.TypeUtils = (function () {
    var _isString = function (value) {
        return typeof value === 'string' || value instanceof String;
    };

    var _isEmptyString = function (value) {
        if (!_isString(value)) throw TypeError('Value is not a string.');

        return value.trim().length === 0;
    };

    var _isNumber = function (value) {
        return typeof value === 'number' || value instanceof Number;
    };

    var _isBoolean = function (value) {
        return typeof value === 'boolean' || value instanceof Boolean;
    };

    var _isDate = function (value) {
        return typeof value === 'object' && value instanceof Date;
    };

    return {
        isString: _isString,
        isEmptyString: _isEmptyString,
        isNumber: _isNumber,
        isBoolean: _isBoolean,
        isDate: _isDate
    };
})();
//Global
var __bugsnagApiKey = 'd44188008a94053ea45f594a424ff1c6';
var __appVersion = '1.8.6';

//Libraries
Class.register('App.Lib');
App.Lib = (function () {
    var _bugsnag;

    var _getGTag = function () {
        if (typeof gtag === "function") {
            return gtag;
        } else {
            return null;
        }
    };

    var _getBugsnag = function () {
        if (typeof Bugsnag === "object") {
            if (!_bugsnag) {
                if (__bugsnagApiKey && __appVersion) {
                    Bugsnag.start({
                        apiKey: __bugsnagApiKey,
                        appVersion: __appVersion,
                        enabledBreadcrumbTypes: [],
                        onError: function (event) {
                            event.setUser(App && App.Context ? App.Context.getSessionId() : null);

                            var errors = event.errors;
                            var error = errors && errors.length > 0 ? errors[0] : null;
                            if (error) {
                                event.groupingHash = event.errorMessage;
                            }
                        }
                    });

                    _bugsnag = Bugsnag;
                }
            }

            return _bugsnag;
        } else {
            return null;
        }
    };

    return {
        getGTag: _getGTag,
        getBugsnag: _getBugsnag
    };
})();

//Index View
Class.register('App.IndexView');
App.IndexView = (function (base, $) {
    /* Private */
    var _load = function (self) {
        //fix dropdown bug
        var dropdownMenu;
        self.select().on('show.bs.dropdown', function (e) {
            var parent = $(e.target);
            dropdownMenu = parent.find('.dropdown-menu');
            self.select().append(dropdownMenu.detach());
            dropdownMenu.css({
                display: 'block',
                position: 'absolute',
                top: parent.offset().top + parent.outerHeight(),
                left: parent.offset().left
            });
        });

        self.select().on('hide.bs.dropdown', function (e) {
            $(e.target).append(dropdownMenu.detach());
            dropdownMenu.hide();
        });
    };

    var _saveState = function (self, path, criteria) {
        var url;
        if (history.replaceState) {
            //get url
            url = _getUrl(self, path, criteria);

            //replace state
            history.replaceState(criteria, document.title, url);
        } else {
            //get url
            url = _getUrl(self, path, criteria);

            //load url
            location.href = url;
        }
    };

    var _getUrl = function (self, path, criteria) {
        var query = '';
        
        for (var p in criteria) {
            var value = criteria[p];
            if (value) {
                if (query) {
                    query += '&';
                } else {
                    query += '?'; 
                }

                query += p + '=' + value;
            }
        }

        return path + query;
    };

    /* Public */
    var self = function (selector) {
        base.call(this, selector);
    };

    Class.extend(self, base);

    self.prototype.load = function () {
        base.prototype.load.call(this);
        _load(this);
    };

    self.prototype.destroy = function () {
        base.prototype.destroy.call(this);
    };

    self.prototype.saveState = function (path, criteria) {
        _saveState(this, path, criteria);
    };

    self.prototype.getUrl = function (path, criteria) {
        return _getUrl(this, path, criteria);
    };

    return self;
})(App.View, jQuery);

//Context
Class.register('App.Context');
App.Context = (function () {
    var _SessionId = 'ad_va_session_id';
    var _Version = 'va_version';

    var _getSessionId = function () {
        return App.Cookie.getValue(_SessionId);
    };

    var _setSessionId = function (value) {
        App.Cookie.setValue(_SessionId, value ? value : '', 1);
    };

    var _getVersion = function () {
        return App.Cookie.getValue(_Version);
    };

    return {
        getSessionId: _getSessionId,
        setSessionId: _setSessionId,
        getVersion: _getVersion
    };
})();

//Item Status
Class.register('App.ItemStatus');
App.ItemStatus = (function () {
    return {
        InStock: 1,
        Reserved: 2,
        Sold: 3,
        Returned: 4,
        LostOrDamaged: 5
    };
})();

//Sales Order Status
Class.register('App.SalesOrderStatus');
App.SalesOrderStatus = (function () {
    return {
        Submitted: 1,
        Canceled: 2,
        Competed: 3
    };
})();

//Shipping Option
Class.register('App.ShippingOption');
App.ShippingOption = (function () {
    return {
        Store: 1,
        TiranaAlbania: 2,
        OtherCityAlbania: 3,
        Kosovo: 4,
        EuropeStandard: 5,
        EuropeExpress: 6,
        USAorCanadaStandard: 7,
        USAorCanadaExpress: 8,
        RestoftheWorldStandard: 9,
        RestoftheWorldExpress: 10
    };
})();

//Payment Method
Class.register('App.PaymentMethod');
App.PaymentMethod = (function () {
    return {
        Cash: 1,
        POS: 2,
        CashPOS: 3,
        PayPal: 4,
        MoneyGram: 5,
        WesternUnion: 6,
        MPesa: 7,
        BankTransfer: 8,
        Mixed: 9
    };
})();

//Document Ready Handler
$(function () {
    //init bugsnag
    var bugsnag = App.Lib.getBugsnag();

    //get user agent
    var userAgent = navigator.userAgent.toLowerCase();

    //warn users if they are using IE < 11
    var ieVersion = userAgent.indexOf('msie') !== -1 ? parseInt(userAgent.split('msie')[1]) : 0;
    if (ieVersion && ieVersion < 11) {
        alert('Ju po përdori Internet Explorer ' + ieVersion + '. Ju lutem përdorni Chrome, Firefox, Safari, Edge ose Internet Explorer 11.');
    }

    //handle api events
    App.EventManager.addEventHandler('UnauthorizedError', function () {
        //go to admin login
        location.href = '/admin/login';
    });

    App.EventManager.addEventHandler('ServerError', function (error) {
        var version = App.Context.getVersion();
        var apiVersion = error.xhr.getResponseHeader('x-version');

        //send bugsnag error
        if (bugsnag) {
            bugsnag.notify(new Error(error.message), function (event) {
                event.severity = 'error';
                event.addMetadata('custom', {
                    type: 'xhr',
                    url: error.url,
                    method: error.method,
                    message: error.message,
                    xhrReadyState: error.xhr.readyState,
                    xhrStatus: error.xhr.status,
                    xhrStatusText: error.xhr.statusText,
                    xhrResponseText: error.xhr.responseText,
                    version: version,
                    apiVersion: apiVersion
                });
            });
        }

        //show message
        alert('Një gabim ka ndodhur. Ju lutem provojeni përsëri.');

        //relod page if versions are different
        if (version && apiVersion && version !== apiVersion) {
            location.reload();
        }
    });

    App.EventManager.addEventHandler('TimeoutError', function () {
        //show message
        alert('Lidhja me serverin është shkëputur. Ju lutem provojeni përsëri.');
    });

    //handle back button
    $('.back-icon').click(function () {
        if (history.length > 0) {
            history.back();
        }
    });

    //load views
    App.ViewManager.loadViews();
});

//Window Load Handler
window.addEventListener('load', function (e) {
    //get bugsnag
    var bugsnag = App.Lib.getBugsnag();

    var handleServiceWorkerRegistrationError = function (error) {
        //get error message
        var message;
        if (typeof error === 'string' || error instanceof String) {
            message = error;
        }
        if (typeof error === 'object' && error instanceof Error) {
            message = error.message;
        }

        //send bugsnag warning
        if (bugsnag) {
            bugsnag.notify(new Error('Service worker registration failed'), function (event) {
                event.severity = 'warning';
                event.addMetadata('custom', {
                    type: 'service worker',
                    message: message
                });
            });
        }
    };

    //check for service worker
    if ("serviceWorker" in navigator) {
        if (navigator.serviceWorker.controller) {
            if (console && console.log) console.log("Active service worker found.");
        } else {
            //register the service worker
            //this may fail for a small number of users and throw an exception
            try {
                navigator.serviceWorker.register("/sw.js").then(function (reg) {
                    if (console && console.log) console.log("Service worker has been registered for scope " + reg.scope + '.');
                }, function (error) {
                    //log error message
                    if (console && console.log) console.log('Service worker registration failed ' + error + '.');

                    //handle error
                    handleServiceWorkerRegistrationError(error);
                });
            } catch (error) {
                //log error message
                if (console && console.log) console.log('Unable to register service worker ' + error + '.');

                //handle error
                handleServiceWorkerRegistrationError(error);
            }
        }
    }
});

//Window Before Unload Handler
window.addEventListener('beforeunload', function (e) {
    App.ViewManager.destroy();
    App.EventManager.destroy();
});
if (!String.prototype.trim) {
    String.prototype.trim = function () {
        return this.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
    };
}

if (!String.prototype.endsWith) {
    String.prototype.endsWith = function (search, length) {
        if (length === undefined || length > this.length) {
            length = this.length;
        }
        return this.substring(length - search.length, length) === search;
    };
}

if (!Number.prototype.toCurrency) {
    Number.prototype.toCurrency = function (symbol) {
        if (this !== null || this !== undefined) {
            var n = this.toString();
            var nd = '';

            var indexOfDot = n.indexOf('.');
            if (indexOfDot >= 0) {
                nd = n.substring(indexOfDot + 1);
                n = n.substring(0, indexOfDot);
            }

            var fn = '';

            for (var i = 0; i < n.length; i++) {
                if (i % 3 === 0 && i > 0) {
                    fn = '.' + fn;
                }

                fn = n.charAt(n.length - i - 1) + fn;
            }

            if (nd) {
                fn = fn + ',' + nd;
            }

            fn = symbol + ' ' + fn;

            return fn;
        } else {
            return '';
        }
    };
}
(function ($) {
    var _display = function (element, method, notes) {
        //clear errors
        _clear(element);
        
        //display messages
        var html = '<div class="alert alert-' + method + ' alert-dismissible fade in" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>';

        if (!(notes instanceof Array)) {
            var array = new Array();
            array.push(notes);
            notes = array;
        }

        $.each(notes, function (index, note) {
            html += '<p>' + note + '</p>';
        });

        html += '</div>';

        $(element).html(html);
    };

    var _clear = function (element) {
        $(element).empty();
    };

    $.fn.notes = function (method, notes) {
        method = method.toLowerCase();

        if (method === 'clear') {
            _clear(this);
        } else {
            _display(this, method, notes);
        }

        return this;
    };
})(jQuery);